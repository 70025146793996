import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./NavbarConstants.module.css";

export const useNavLinks = () => {
  const { t } = useTranslation();

  const navLinks = [
    {
      id: "about",
      title: t("navbar.about"),
      items: [
        {
          key: "1",
          label: (
            <Link to="/what-is-dmk" className={styles.dropdownLink}>
              {t("navbar.what")}
            </Link>
          ),
        },
        {
          key: "2",
          label: (
            <Link to="/philosophy" className={styles.dropdownLink}>
              {t("navbar.philosophy")}
            </Link>
          ),
        },
        {
          key: "3",
          label: (
            <Link to="/growth" className={styles.dropdownLink}>
              {t("navbar.journey")}
            </Link>
          ),
        },
        {
          key: "4",
          label: (
            <Link to="/teaching-system" className={styles.dropdownLink}>
              {t("navbar.teaching")}
            </Link>
          ),
        },
        {
          key: "5",
          label: (
            <Link to="/resources" className={styles.dropdownLink}>
              {t("navbar.resources")}
            </Link>
          ),
        },
        {
          key: "6",
          label: (
            <Link to="/faq" className={styles.dropdownLink}>
              {t("navbar.faq")}
            </Link>
          ),
        },
      ],
    },
    {
      id: "curriculum",
      title: t("navbar.curriculum"),
      items: [
        {
          key: "1",
          label: (
            <Link to="/egg" className={styles.dropdownLink}>
              {t("navbar.egg")}
            </Link>
          ),
        },
        {
          key: "2",
          label: (
            <Link to="/caterpillar" className={styles.dropdownLink}>
              {t("navbar.caterpillar")}
            </Link>
          ),
        },
        {
          key: "3",
          label: (
            <Link to="/pupa" className={styles.dropdownLink}>
              {t("navbar.pupa")}
            </Link>
          ),
        },
        {
          key: "4",
          label: (
            <Link to="/cocoon" className={styles.dropdownLink}>
              {t("navbar.cocoon")}
            </Link>
          ),
        },
        {
          key: "5",
          label: (
            <Link to="/butterfly" className={styles.dropdownLink}>
              {t("navbar.butterfly")}
            </Link>
          ),
        },
      ],
    },
    {
      id: "enrichment",
      title: t("navbar.enrichment"),
    },
    {
      id: "activities",
      title: t("navbar.activities"),
      items: [
        {
          key: "1",
          label: (
            <Link to="/activities" className={styles.dropdownLink}>
              {t("navbar.all_activities")}
            </Link>
          ),
        },
        {
          key: "2",
          label: (
            <Link to="/calendar" className={styles.dropdownLink}>
              {t("navbar.calendar")}
            </Link>
          ),
        },
      ],
    },
    {
      id: "team",
      title: t("navbar.team"),
    },
  ];

  return navLinks;
};
