import React from "react";
import styles from "./Curriculum.module.css";
import { useCurriculums } from "../../constants/Curriculums";
import Timetable from "./Timetable";
import { Link } from "react-scroll";
import { useParams } from "react-router-dom";

export default function Curriculum() {
  const { classId } = useParams();
  const { curriculums } = useCurriculums();
  const selectedClass = curriculums.find(
    (curriculum) => curriculum.id === classId
  );

  if (!selectedClass) {
    return <div>Not Found</div>;
  }

  return (
    <>
      <div className={styles.curriculumMain}>
        <div className={styles.curriculumContent}>
          <h2 className={styles.headingSecondary}>{selectedClass.heading} </h2>
          <span className={styles.curriculumText}>{selectedClass.text}</span>
        </div>
        <div className={styles.gallery}>
          {selectedClass.images.map((image, index) => (
            <div className={styles.galleryItem} key={index}>
              <img src={image} alt="kids" />
            </div>
          ))}
        </div>
        <Link
          className={styles.linkScroll}
          to="timetable"
          smooth={true}
          duration={1000}
        >
          <div className={styles.arrow}></div>
          <div className={styles.arrowTwo}></div>
        </Link>
      </div>
      <div id="timetable" className={styles.timetable}>
        <Timetable />
      </div>
    </>
  );
}
