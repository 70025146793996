import { useTranslation } from "react-i18next";
import {
  caterpillar1,
  caterpillar2,
  caterpillar3,
  caterpillar4,
  caterpillar5,
  caterpillar6,
  caterpillar7,
  caterpillar8,
  caterpillar9,
  pupa1,
  pupa2,
  pupa3,
  pupa4,
  pupa5,
  pupa6,
  pupa7,
  pupa8,
  pupa9,
  egg1,
  egg2,
  egg3,
  egg4,
  egg5,
  egg6,
  egg7,
  egg8,
  egg9,
  cocoon1,
  cocoon2,
  cocoon3,
  cocoon4,
  cocoon5,
  cocoon6,
  cocoon7,
  cocoon8,
  cocoon9,
  butterfly1,
  butterfly2,
  butterfly3,
  butterfly4,
  butterfly5,
  butterfly6,
  butterfly7,
  butterfly8,
  butterfly9,
  breakfast,
  bye,
  cashew,
  child,
  notes,
  playball,
  reading,
  science,
  theatre,
  triangle,
  welcome,
} from "../assets/index";

export const useCurriculums = () => {
  const { t } = useTranslation();

  const curriculums = [
    {
      id: "pupa",
      images: [pupa1, pupa2, pupa3, pupa4, pupa9, pupa5, pupa6, pupa7, pupa8],
      text: t("curriculum.pupaText"),
      heading: t("curriculum.pupaHeading"),
    },
    {
      id: "egg",
      images: [egg1, egg2, egg3, egg4, egg5, egg6, egg7, egg8, egg9],
      text: t("curriculum.eggText"),
      heading: t("curriculum.eggHeading"),
    },
    {
      id: "cocoon",
      images: [
        cocoon1,
        cocoon2,
        cocoon3,
        cocoon4,
        cocoon5,
        cocoon6,
        cocoon7,
        cocoon8,
        cocoon9,
      ],
      text: t("curriculum.cocoonText"),
      heading: t("curriculum.cocoonHeading"),
    },
    {
      id: "caterpillar",
      images: [
        caterpillar1,
        caterpillar2,
        caterpillar3,
        caterpillar4,
        caterpillar5,
        caterpillar6,
        caterpillar7,
        caterpillar8,
        caterpillar9,
      ],
      text: t("curriculum.caterpillarText"),
      heading: t("curriculum.caterpillarHeading"),
    },
    {
      id: "butterfly",
      images: [
        butterfly1,
        butterfly2,
        butterfly3,
        butterfly4,
        butterfly5,
        butterfly6,
        butterfly7,
        butterfly8,
        butterfly9,
      ],
      text: t("curriculum.butterflyText"),
      heading: t("curriculum.butterflyHeading"),
    },
  ];
  const timetable = [
    {
      title: t("timetable.welcome"),
      icon: welcome,
      time: `8:30-9:00`,
    },
    {
      title: t("timetable.reading"),
      icon: reading,
      time: `9:00-10:00`,
    },
    {
      title: t("timetable.triangle"),
      icon: triangle,
      time: `10:00-11:00`,
    },
    {
      title: t("timetable.playball"),
      icon: playball,
      time: `11:00-11:30`,
    },
    {
      title: t("timetable.science"),
      icon: science,
      time: `11:30-12:00`,
    },
    {
      title: t("timetable.breakfast"),
      icon: breakfast,
      time: `12:00-12:30`,
    },
    {
      title: t("timetable.child"),
      icon: child,
      time: `12:30-14:30`,
    },
    {
      title: t("timetable.cashew"),
      icon: cashew,
      time: `14:30-14:50`,
    },
    {
      title: t("timetable.notes"),
      icon: notes,
      time: `14:50-15:30`,
    },
    {
      title: t("timetable.theatre"),
      icon: theatre,
      time: `15:30-16:00`,
    },
    {
      title: t("timetable.bye"),
      icon: bye,
      time: `16:00`,
    },
  ];

  return { curriculums, timetable };
};
