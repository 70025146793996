import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "../src/languages/en.json";
import cnTranslation from "../src/languages/cn.json";

const lng = localStorage.getItem("language") || "EN";

i18n.use(initReactI18next).init({
  resources: {
    EN: {
      translation: enTranslation,
    },
    中文: {
      translation: cnTranslation,
    },
  },
  lng: lng,
  fallbackLng: lng,
  interpolation: {
    escapeValue: false,
  },
  react: {
    bindI18n: "languageChanged",
    useSuspense: false,
  },
});

export default i18n;
