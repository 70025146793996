import React, { useState, useEffect } from "react";
import { Radio, Input } from "antd";
import styles from "./Worksheet.module.css";
import { jsPDF } from "jspdf";
import { addCustomFont } from "./font";
import { addCustomDottedFont } from "./font-dotted";
import { addCustomNormalFont } from "./font-normal";
import { addCustomDashedFont } from "./font-dashed";

export default function Worksheet() {
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");

  const [font, setFont] = useState("KGPrimaryDotsLined");

  const handleTextChange = (event) => {
    setText(event.target.value);
  };
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleReset = () => {
    setText("");
    setTitle("");
    setFont("KGPrimaryDotsLined");
  };

  useEffect(() => {
    addCustomFont();
    addCustomDottedFont();
    addCustomDashedFont();
    addCustomNormalFont();
  }, []);

  const handleFontChange = (event) => {
    setFont(event.target.value);
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    const titleFontSize = 32;
    const textFontSize = 62;
    const nameFontSize = 16;
    // addCustomFont();
    doc.setFont("Montserrat-Bold", "bold"); //font for PDF
    doc.setFontSize(titleFontSize); // Set the font size for title
    doc.text(title || "Worksheet", doc.internal.pageSize.getWidth() / 2, 15, {
      align: "center",
    });

    doc.setFontSize(nameFontSize);
    doc.text(
      "name: _____________   date: _____________ ",
      doc.internal.pageSize.getWidth() / 2,
      35,
      {
        align: "center",
      }
    );
    // addCustomDottedFont();
    doc.addImage("logo.png", "PNG", 168, 2, 35, 35);
    doc.setFontSize(textFontSize);
    doc.setFont(font, "normal");

    const textOptions = {
      maxWidth: doc.internal.pageSize.width - 10, // Adjust the width as needed
      // align: "center",
    };
    const textLines = doc.splitTextToSize(text, textOptions.maxWidth);
    let startY = 70;
    let currentPage = 1;

    for (let i = 0; i < textLines.length; i++) {
      if (startY + 15 > doc.internal.pageSize.height) {
        doc.addPage();
        startY = 50; // Adjust the starting position for the new page
        currentPage++;
      }

      doc.text(10, startY, textLines[i], textOptions);
      startY += 25; // Adjust the line spacing as needed
    }

    doc.save("DMK-worksheet.pdf");
  };

  const handleDownloadBlankPDF = () => {
    const doc = new jsPDF();

    doc.setFont("Montserrat-Bold", "bold"); //font for PDF
    doc.setFontSize(32); // Set the font size for title
    doc.text("Handwriting Practice", 30, 15); // Centered title

    doc.setFontSize(16);
    doc.text("name: _____________   date: _____________ ", 40, 35);
    doc.addImage("logo.png", "PNG", 168, 2, 35, 35);
    doc.save("DMK-worksheet.pdf");
  };
  const handleDownloadLinedPDF = () => {
    const doc = new jsPDF();

    doc.setFont("Montserrat-Bold", "bold"); //font for PDF
    doc.setFontSize(32); // Set the font size for title
    doc.text("Handwriting Practice", 30, 15); // Centered title

    doc.setFontSize(16);
    doc.text("name: _____________   date: _____________ ", 40, 35);
    doc.addImage("logo.png", "PNG", 168, 2, 35, 35);
    const lineSpacing = 20; // Adjust the spacing between lines
    const startX = 20; // X-coordinate for the start of lines
    const endX = doc.internal.pageSize.width - 20; // X-coordinate for the end of lines

    for (
      let startY = 70;
      startY < doc.internal.pageSize.height;
      startY += lineSpacing
    ) {
      doc.line(startX, startY, endX, startY); // Draw a line
    }

    doc.save("DMK-worksheet.pdf");
  };
  return (
    <div className={styles.main}>
      <div className={styles.textContainer}>
        <div className={styles.radioButtons}>
          <span>Choose font:</span>
          <Radio.Group onChange={handleFontChange} value={font}>
            <Radio className={styles.radioDotted} value="KGPrimaryDotsLined">
              hello
            </Radio>
            <Radio className={styles.radioNormal} value="print_clearly_tt">
              hello
            </Radio>
            <Radio className={styles.radioDashed} value="print_dashed_tt">
              hello
            </Radio>
          </Radio.Group>
        </div>
        <div className={styles.inputTitle}>
          <span>Title (optional):</span>
          <div className={styles.inputDiv}>
            <Input
              onChange={handleTitleChange}
              placeholder="Worksheet"
              value={title}
            />
          </div>
        </div>

        <textarea
          className={styles.textarea}
          placeholder="Type something here"
          value={text}
          style={{ fontFamily: font }}
          onChange={handleTextChange}
        />
        <button className={styles.downloadButton} onClick={handleReset}>
          Reset
        </button>
      </div>
      <div className={styles.previewContainer}>
        <h2>Preview:</h2>
        <div className={styles.preview}>
          <img className={styles.logo} src="../../../logo.png" alt="logo" />
          <span className={styles.title}>{title || "Worksheet"}</span>
          <span className={styles.name}>
            name: _____________ date: _____________
          </span>
          <div className={styles.text} style={{ fontFamily: font }}>
            {text}
          </div>
        </div>
        <div className={styles.buttons}>
          <button className={styles.downloadButton} onClick={handleDownloadPDF}>
            Download PDF
          </button>
          <button
            className={styles.downloadButtonBlank}
            onClick={handleDownloadBlankPDF}
          >
            Download Blank Worksheet
          </button>
          <button
            className={styles.downloadButtonBlank}
            onClick={handleDownloadLinedPDF}
          >
            Download Lined Worksheet
          </button>
        </div>
      </div>
    </div>
  );
}
