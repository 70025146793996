import React from "react";
import Navbar from "../Navbar/Navbar";
import { Outlet, useParams } from "react-router-dom";
import styles from "./Main.module.css";
import Footer from "../Footer/Footer";
import Curriculum from "../Curriculums/Curriculum";

export default function Main() {
  const { classId } = useParams();

  return (
    <div className={styles.main}>
      <Navbar />
      <div className={styles.content}>
        {classId ? <Curriculum /> : <Outlet />}{" "}
      </div>
      <Footer className={styles.footer} />
    </div>
  );
}
