import React from "react";
import styles from "./Timetable.module.css";
import { useCurriculums } from "../../constants/Curriculums";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

const TimeCard = ({ time }) => (
  <VerticalTimelineElement
    className={styles.timeCard}
    contentArrowStyle={{ borderRight: "7px solid #232631" }}
    date={time.time}
    iconStyle={{ background: "#aaa6c3" }}
    icon={
      <div className={styles.iconDiv}>
        <img src={time.icon} alt="1" className={styles.timeCardIcon} />
      </div>
    }
  >
    <div>
      <h3 className={styles.timeCard__title}>{time.title}</h3>
    </div>
  </VerticalTimelineElement>
);

export default function Timetable() {
  const { timetable } = useCurriculums();
  return (
    <div className={styles.timeline}>
      <VerticalTimeline>
        {timetable.map((time, index) => {
          return <TimeCard key={index} time={time} />;
        })}
      </VerticalTimeline>
    </div>
  );
}
