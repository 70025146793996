import React, { useState } from "react";
import styles from "./LanguageDropDown.module.css";
import i18n from "i18next";

const LanguageDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("language") || "EN"
  );

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    localStorage.setItem("language", language);
    i18n.changeLanguage(language);
    setIsOpen(false);
  };

  return (
    <div className={styles.mainDiv}>
      <button
        type="button"
        className={styles.button}
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedLanguage}
      </button>

      {isOpen && (
        <div className={styles.languageMenu}>
          <div className="py-1">
            <button
              type="button"
              className={styles.languageButton}
              onClick={() => {
                handleLanguageChange("EN");
              }}
            >
              <div className="flex items-center content-center">
                <span className="ml-2">EN</span>
              </div>
            </button>

            <button
              type="button"
              className={styles.languageButton}
              onClick={() => {
                handleLanguageChange("中文");
              }}
            >
              <div className="flex items-center content-center">
                <span className="ml-2">中文</span>
              </div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageDropdown;
