import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Zoom } from "react-awesome-reveal";
import styles from "./Navbar.module.css";
import { Dropdown } from "antd";
import LanguageDropDown from "../LanguageDropDown/LanguageDropDown";
import { useNavLinks } from "../../constants/NavbarConstants";
import { menu, close } from "../../assets";
import { useLocation } from "react-router-dom";

export default function Navbar() {
  const location = useLocation();
  const navLinks = useNavLinks();
  const [toggle, setToggle] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const handleOpenChange = (link, open) => {
    if (open) {
      setOpenDropdownId(link.id);
    } else {
      setOpenDropdownId(null);
    }
  };
  useEffect(() => {
    setToggle(false);
  }, [location]);

  const navigate = useNavigate();
  const handleClassSelect = (classId) => {
    navigate(`/${classId}`);
    setToggle(false);
  };

  return (
    <div className={styles.navbar}>
      <Zoom delay="50">
        <div className={styles.logoDiv}>
          <Link to="/">
            <img className={styles.logo} src="../../../logo.png" alt="logo" />
          </Link>
          <Link to="/">
            <img
              className={styles.logoWord}
              src="../../../logo-footer.png"
              alt="logo"
            />
          </Link>
        </div>
      </Zoom>
      <div className={styles.navLinks}>
        {navLinks?.map((link) => {
          if (link.items) {
            const items = link.items; // Create a separate variable named 'items'
            const isOpen = openDropdownId === link.id;
            return (
              <Dropdown
                key={link.id}
                menu={{ items }}
                className={isOpen ? styles.dropdown : ""}
                onOpenChange={(open) => handleOpenChange(link, open)}
                trigger={["hover", "click"]}
                placement="top"
              >
                <span className={styles.link}>{link.title}</span>
              </Dropdown>
            );
          } else {
            return (
              <span
                className={styles.link}
                key={link.id}
                onClick={() => handleClassSelect(link.id)}
              >
                {link.title}
              </span>
            );
          }
        })}
      </div>
      <div className={styles.navbarLinksMobile}>
        <div
          className={styles.menuIconWrapper}
          onClick={() => setToggle(!toggle)}
        >
          <img
            src={toggle ? close : menu}
            alt={menu}
            className={styles.menuMobile}
          />
        </div>

        {toggle ? (
          <div className={styles.navLinksMobile}>
            {navLinks?.map((link) => {
              if (link.items) {
                const items = link.items; // Create a separate variable named 'items'
                const isOpen = openDropdownId === link.id;
                return (
                  <Dropdown
                    key={link.id}
                    menu={{ items }}
                    className={isOpen ? styles.dropdown : ""}
                    onOpenChange={(open) => handleOpenChange(link, open)}
                    trigger={["hover", "click"]}
                    placement="top"
                    // open
                  >
                    <span className={styles.link}>{link.title}</span>
                  </Dropdown>
                );
              } else {
                return (
                  <span
                    className={styles.link}
                    key={link.id}
                    onClick={() => handleClassSelect(link.id)}
                  >
                    {link.title}
                  </span>
                );
              }
            })}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className={styles.languageDropDown}>
        <LanguageDropDown />
      </div>
    </div>
  );
}
