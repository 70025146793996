import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./Footer.module.css";
export default function Footer() {
  const [isQRCodeVisible, setQRCodeVisible] = useState(false);

  const toggleQRCode = () => {
    setQRCodeVisible(!isQRCodeVisible);
  };
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/future");
  };
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <div className={styles.footer}>
      <div className={styles.years}>
        <span className={styles.years}>© 2007 - {currentYear}</span>
        <span className={styles.years}>{t("footer.kinder")}</span>
      </div>

      <div className={styles.future_div} onClick={handleClick}>
        <img
          className={styles.future}
          src="./logos/dm-future.png"
          alt="future"
        />
        <div className={styles.spans}>
          <span>{t("footer.future1")}</span>
          <span className={styles.age}>{t("footer.future2")}</span>
        </div>
        <img className={styles.click} src="./icons/click.png" alt="click" />
      </div>
      <div className={styles.icons}>
        <img
          className={`${styles.iconWechat} ${
            isQRCodeVisible ? styles.active : ""
          }`}
          onClick={toggleQRCode}
          src="./logos/chat.png"
          alt="wechat"
        />
        <a
          href="https://www.xiaohongshu.com/user/profile/60cc933f0000000001008e07?xhsshare=CopyLink&appuid=64be37dd000000001403ba5e&apptime=1690187900"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className={styles.iconBook}
            src="./logos/book1.png"
            alt="xiao hong shu"
          />
        </a>
        <a
          href="https://www.douyin.com/user/MS4wLjABAAAAXDlYOE-u2wvi_5utAgJvQewrfXUN77uBHk43WA10J2s"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className={styles.iconTikTok}
            src="./logos/tok.png"
            alt="tik tok"
          />
        </a>
        {isQRCodeVisible && (
          <div className={styles.qrCode}>
            <img
              className={styles.qr1}
              src="./logos/qr-code.jpg"
              alt="QR Code"
            />
            <img
              className={styles.qr2}
              src="./logos/qr-code2.jpg"
              alt="QR Code"
            />
          </div>
        )}
      </div>
    </div>
  );
}
