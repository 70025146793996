import { Routes, Route } from "react-router-dom";
import Main from "./components/Main/Main";
import React, { lazy, Suspense } from "react";
import { Skeleton } from "antd";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import Worksheet from "./components/Resources/Worksheet";
const LazyCurriculum = lazy(() =>
  import("./components/Curriculums/Curriculum")
);
const LazyActivities = lazy(() => import("./components/Activities/Activities"));
const LazyEnrichment = lazy(() => import("./components/Enrichment/Enrichment"));
const LazyTeam = lazy(() => import("./components/OurTeam/OurTeam"));
const LazyTeachingSystem = lazy(() =>
  import("./components/TeachingSystem/TeachingSystem")
);
const LazyPhilosophy = lazy(() => import("./components/Philosophy/Philosophy"));
const LazyFaq = lazy(() => import("./components/FAQs/Faq"));
const LazyGrowth = lazy(() => import("./components/Growth/Growth"));
const LazyWhatIsDMK = lazy(() => import("./components/WhatIsDMK/WhatIsDmk"));
const LazyCarousel = lazy(() =>
  import("./components/Carousel/CarouselComponent")
);
const LazyCalendar = lazy(() => import("./components/Activities/Calendar"));
const LazyDmfuture = lazy(() => import("./components/DMFuture/Dmfuture"));
const LazyResources = lazy(() => import("./components/Resources/Resources"));
const LazySummerCamp = lazy(() => import("./components/SummerCamp/SummerCamp"));

function App() {
  return (
    <>
      <div className="App">
        <TawkMessengerReact
          propertyId="64d8b031cc26a871b02ef4a4"
          widgetId="1h7n70h04"
        />
      </div>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route
            path="/"
            element={
              <Suspense fallback={<Skeleton active />}>
                <LazyCarousel />
              </Suspense>
            }
          />
          <Route
            path="what-is-dmk"
            element={
              <Suspense fallback={<Skeleton active />}>
                <LazyWhatIsDMK />
              </Suspense>
            }
          />
          <Route
            path="resources"
            element={
              <Suspense fallback={<Skeleton active />}>
                <LazyResources />
              </Suspense>
            }
          />
          <Route
            path="worksheet"
            element={
              <Suspense fallback={<Skeleton active />}>
                <Worksheet />
              </Suspense>
            }
          />
          <Route
            path="growth"
            element={
              <Suspense fallback={<Skeleton active />}>
                <LazyGrowth />
              </Suspense>
            }
          />
          <Route
            path="/:classId"
            element={
              <Suspense fallback={<Skeleton active />}>
                <LazyCurriculum />
              </Suspense>
            }
          />
          <Route
            path="enrichment"
            element={
              <Suspense fallback={<Skeleton active />}>
                <LazyEnrichment />
              </Suspense>
            }
          />
          <Route
            path="activities"
            element={
              <Suspense fallback={<Skeleton active />}>
                <LazyActivities />
              </Suspense>
            }
          />
          <Route
            path="calendar"
            element={
              <Suspense fallback={<Skeleton active />}>
                <LazyCalendar />
              </Suspense>
            }
          />
          <Route
            path="team"
            element={
              <Suspense fallback={<Skeleton active />}>
                <LazyTeam />
              </Suspense>
            }
          />
          <Route
            path="faq"
            element={
              <Suspense fallback={<Skeleton active />}>
                <LazyFaq />
              </Suspense>
            }
          />
          <Route
            path="philosophy"
            element={
              <Suspense fallback={<Skeleton active />}>
                <LazyPhilosophy />
              </Suspense>
            }
          />
          <Route
            path="teaching-system"
            element={
              <Suspense fallback={<Skeleton active />}>
                <LazyTeachingSystem />
              </Suspense>
            }
          />
          <Route
            path="future"
            element={
              <Suspense fallback={<Skeleton active />}>
                <LazyDmfuture />
              </Suspense>
            }
          />
          <Route
            path="summer-camp"
            element={
              <Suspense fallback={<Skeleton active />}>
                <LazySummerCamp />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </>
  );
}

export default App;
